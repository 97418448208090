import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/Seo'
import Layout from '../components/Layout'
import ContentContainer from '../components/ContentContainer'
import ProjectInfo from '../components/ProjectInfo'
import VimeoPlayer from '../components/VimeoPlayer'
import VideoShowcase from '../components/VideoShowcase'

const TonyLugo = ({ data }) => {
  const project = data.markdownRemark.frontmatter

  return (
    <Layout hideFooter>
      <SEO title={`${project.artist} | ${project.projectTitle}`} />
      <ContentContainer centered>
        <VideoShowcase style={{ minHeight: '100rvh' }}>
          <VimeoPlayer Src="https://player.vimeo.com/video/397581962?background=1&autopause=0" />
        </VideoShowcase>
      </ContentContainer>
      <ProjectInfo
        projectTitle={project.projectTitle}
        projectDescription={project.projectDescription}
        artist={project.artist}
        artistHeadshot={project.artistHeadshot.childImageSharp.fluid}
        artistDescription={project.artistDescription}
        website={project.website}
        instagram={project.instagram}
        twitter={project.twitter}
        email={project.email}
      />
    </Layout>
  )
}

export default TonyLugo

export const TainaCruzQuery = graphql`
  query TonyLugoQuery {
    markdownRemark(fields: { slug: { eq: "/tony-lugo/" } }) {
      id
      frontmatter {
        artist
        artistHeadshot {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        artistDescription
        projectTitle
        projectDescription
        website
        instagram
        twitter
        email
      }
    }
  }
`
