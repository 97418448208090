import styled from 'styled-components'
import { spacing, color, breakpoints } from '../../utils/constants.js'

import RightArrow from '../../assets/images/icon--arrow-left.svg'

const TextButton = styled.a`
  display: inline-block;
  position: relative;
  margin: 0 ${spacing.xs};
  ${props => (props.largeButton ? 'font-size: 2.666666667rem;' : null)}
  padding-right: ${props => (props.largeButton ? '3.25rem;' : '1.5rem')};
  line-height: 1;
  color: ${color.baseDark};
  text-decoration: none;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: ${props => (props.largeButton ? '2.666666667rem;' : '1rem')};
    height: ${props => (props.largeButton ? '2.666666667rem;' : '1rem')};
    background-image: url(${RightArrow});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: transform 0.2s ease-in;

    @media (max-width: ${breakpoints.mobile}) {
      ${props => (props.largeButton ? 'width: 1.25rem;' : null)}
      ${props => (props.largeButton ? 'height: 1.25rem;' : null)}
    }
  }

  &:hover {
    &::after {
      transform: translate3d(0.5rem, 0, 0);
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    margin: ${spacing.xs} 0;
    ${props => (props.largeButton ? 'font-size: 1.25rem;' : null)}
    ${props => (props.largeButton ? 'padding-right: 2rem;' : null)}
  }
`

export default TextButton
