import React from 'react'
import styled from 'styled-components'

const VideoWrapper = styled.div`
  position: ${props => (props.videoBackground ? 'absolute' : 'relative')};
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => (props.videoBackground ? '100%' : 'auto')};
  ${props => (props.videoBackground ? null : 'padding-bottom: 56.25%;')};
  z-index: ${props => (props.videoBackground ? '-1' : '1')};
  ${props => (props.videoBackground ? 'pointer-events: none;' : null)};
  overflow: hidden;
`
const Video = styled.iframe`
  width: ${props => (props.videoBackground ? '100vw' : '100%')};
  height: ${props => (props.videoBackground ? '56.25vw' : '100%')};
  ${props => (props.videoBackground ? 'min-height: 100vh;' : null)};
  ${props => (props.videoBackground ? 'min-width: 177.77vh;' : null)};
  position: absolute;
  top: ${props => (props.videoBackground ? '50%' : '0')};
  left: ${props => (props.videoBackground ? '50%' : '0')};

  ${props =>
    props.videoBackground ? 'transform: translate(-50%, -50%);' : null};
  border: none;
`

export default function VimeoPlayer(props) {
  return (
    <VideoWrapper videoBackground={props.videoBackground}>
      <Video
        src={props.Src}
        videoBackground={props.videoBackground}
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
      />
    </VideoWrapper>
  )
}
