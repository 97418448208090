import styled from 'styled-components'
import Div100vh from 'react-div-100vh'

import { spacing, breakpoints } from '../../utils/constants'

const VideoShowcase = styled(Div100vh)`
  display: flex;
  width: 100%;
  margin-top: -${spacing.lg};
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${breakpoints.mobile}) {
    margin-top: -${spacing.md};
  }
`

export default VideoShowcase
