import React, { useState, useEffect } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { spacing, color, breakpoints } from '../../utils/constants'

import MenuButton from '../MenuButton'
import ContentContainer from '../ContentContainer'
import TextButton from '../TextButton'

const ProjectNav = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  transition: height 0.3s ease-in;
  height: ${props => (props.menuActive ? '100%' : '0')};
  z-index: 10;
`

const MenuButtonWrap = styled.div`
  position: absolute;
  bottom: ${spacing.sm};
  right: 0;
`

const ProjectInfoContainer = styled.div`
  height: ${props => (props.menuActive ? '100%' : 'auto')};
  padding: ${spacing.md} 0 ${spacing.lg} 0;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: ${color.baseLight};
  transition: transform 0.3s ease-in, height 0.3s ease-in;
  transform: ${props =>
    props.menuActive ? 'translate3d(0,0,0)' : 'translate3d(0,100%,0)'};
`

const ProjectTitle = styled.h1`
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`
const ArtistDetails = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`
const ArtsitHeadshotWrap = styled.div`
  padding-right: ${spacing.sm};
`

const ArtsitHeadshot = styled(Img)`
  flex-grow: 1;
  width: ${spacing.lg};
  overflow:: hidden;
  border-radius: 50%;
`
const ProjectDescription = styled.div`
  margin: 0 auto;
  max-width: 900px;
`

const ArtistDescription = styled.div`
  margin: 0 auto;
  max-width: 900px;
`

const BioHeadline = styled.h4`
  text-align: center;
`

const Bio = styled.div`
  & p {
    font-size: 1.222222222rem;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: 1rem;
    }
  }
`

const ArtistSocial = styled.div``

const SocialHeadline = styled.h4`
  text-align: center;
`

const SocialContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
  }
`

export default function ProjectInfo(props) {
  const [isMenuOpen, setMenu] = useState(false)

  const keyPressHandler = event => {
    let key = event.key || event.keyCode

    if (key === 'Escape' || key === 'Esc' || key === 27) {
      setMenu(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', keyPressHandler)
    return () => {
      window.addEventListener('keyup', keyPressHandler)
    }
  }, [])

  return (
    <ProjectNav menuActive={isMenuOpen}>
      <ProjectInfoContainer menuActive={isMenuOpen}>
        <ContentContainer displayColmun>
          <ProjectTitle>{props.projectTitle}</ProjectTitle>
          <ArtistDetails>
            <ArtsitHeadshotWrap>
              <ArtsitHeadshot fluid={props.artistHeadshot} />
            </ArtsitHeadshotWrap>
            <h3>{props.artist}</h3>
          </ArtistDetails>
          <ProjectDescription
            dangerouslySetInnerHTML={{ __html: props.projectDescription }}
          ></ProjectDescription>
          <ArtistDescription>
            <BioHeadline>About {props.artist}</BioHeadline>
            <Bio
              dangerouslySetInnerHTML={{ __html: props.artistDescription }}
            />
          </ArtistDescription>
          <ArtistSocial>
            <SocialHeadline>Follow {props.artist}</SocialHeadline>
            <SocialContainer>
              {props.website && (
                <TextButton href={props.website}>Website</TextButton>
              )}
              {props.instagram && (
                <TextButton href={props.instagram}>Instagram</TextButton>
              )}
              {props.twitter && (
                <TextButton href={props.twitter}>Twitter</TextButton>
              )}
              {props.email && (
                <TextButton href={`mailto:${props.email}`}>Email</TextButton>
              )}
            </SocialContainer>
          </ArtistSocial>
        </ContentContainer>
      </ProjectInfoContainer>
      <MenuButtonWrap>
        <MenuButton
          onClick={() => setMenu(!isMenuOpen)}
          menuActive={isMenuOpen}
        >
          {isMenuOpen ? 'Close' : 'Info'}
        </MenuButton>
      </MenuButtonWrap>
    </ProjectNav>
  )
}
